.fade-in-container {
  opacity: 0;
  transform: translate(0, 20vh);
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in-out;
  will-change: opacity, visibility;
}

.fade-in-container.is-visible {
  transform: none;
  visibility: visible;
  opacity: 1;
}
