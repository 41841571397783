.main-container {
  margin-top: 15vh;
  width: 85vw;
  height: 70vh;
  justify-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10vh;
}

.header {
  color: var(--primary);
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 20px;
}

.description {
  color: var(--text);
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 25px;
}

.launch-date {
  color: var(--primary);
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 25px;
}

.left-container {
  width: 60%;
  height: 100%;
  align-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: fit-content;
  margin-bottom: 30px;
  margin-left: 0;
}

.app-store-button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.button {
  height: 7.5vh;
  /* height: 75px; */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: 8px;
}

.button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 35%;
  height: 100%;
}

.phone-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.phone-image {
  height: 100%;
  width: 100%;
  margin: 0;
  height: 100%;
  position: relative;
}

.glow-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glow-container::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle, var(--primary) 0%, rgba(66, 213, 87, 0) 50%);
  filter: blur(80px);
  border-radius: 50%;
  z-index: 0;
}

.single-button {
  height: 75px;
  max-width: 300px;
}

.waitlist-button {
  max-width: 80%;
  width: 350px;
  height: 75px;
  border-radius: 15px;
  background-color: var(--secondary);
  color: var(--text);
  font-size: 24px;
  font-weight: 600;
}

.waitlist-button:hover {
  transition: background-color 0.1s ease-in;
  transition: width 0.1s ease-in;
  transition: height 0.1s ease-in;
  width: 360px;
  height: 80px;
  background-color: var(--primary);
}

@media (max-width: 1024px) {
  .main-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 20px;
  }

  .left-container {
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .right-container {
    width: 100%;
    margin-top: 30px;
  }

  .description {
    font-size: 24px;
  }

  .header {
    font-size: 36px;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .button {
    height: 60px;
  }
}

@media (max-width: 480px) {
  .main-container {
    padding: 10px;
  }

  .header {
    font-size: 48px;
  }

  .description {
    font-size: 18px;
    margin: 10px;
  }

  .phone-image {
    width: 90%;
    height: 100%;
  }

  .button {
    height: 50px;
  }
}
