@import "../../App.css";

.parent-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  height: 100vh;
}

.header-container {
  width: 100%;
  background-color: #282832;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 100;
}

.form-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.form-header {
  color: var(--primary); /* Primary */
  text-align: center;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
}

.secondary-container {
  margin-top: 5%;
  width: 100%;
}

.input {
  font-size: 1.25rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  background-color: var(--textTwo);
  border-color: var(--darkGreen);
  border-radius: 10px;
  border-width: 3px;
  border-style: solid;
  padding: 5px;
}

.input:focus {
  border-color: var(--primary);
  background-color: #cfcfcf;
  outline: none;
}

label {
  padding-bottom: 50px;
  font-size: 1.25rem;
  color: white;
}

.password-container {
  width: 50%;
  display: "flex";
  flex-direction: column;
}

.input-container {
  margin-top: 20px;
}

.password-criteria-container {
  display: flex;
  padding-top: 5px;
  gap: 5px;
  flex-direction: column;
}

.password-criteria-valid {
  color: var(--primary);
}

.password-criteria-invalid {
  color: var(--lightRedBackground);
}

.button-container {
  width: 25%;
  margin-top: 20px;
}

@media only screen and (max-width: 855px) {
  .password-container {
    width: 65%;
  }

  .button-container {
    width: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .password-container {
    width: 80%;
  }

  .button-container {
    width: 50%;
  }

  .form-header {
    font-size: 1.5rem;
  }
}

.subtext {
  color: var(--text);
  font-size: 1rem;
  text-align: center;
  width: 100%;
}
