:root {
  --text: #ffffff;
  --textTwo: #bbbbbb;
  --lightBackground: #282832;
  --darkBackground: #1e202a;
  --primary: #42d557;
  --primaryHover: #1fe23c;
  --secondary: #409f4f;
  --greyAccent: #4b4c54;
  --lightGreenBackground: #bef264;
  --lightRedBackground: #f87171;
  --redBackground: #cc2323;
  --redAccent: #a61919;

  --lightGreen: #4aed52;
  --darkGreen: #1f8f25;
  --lightBlue: #33d2de;
  --darkBlue: #2e4eb8;
  --yellow: #fae637;
  --orange: #ffad33;
  --pink: #da64e3;
  --red: #db1247;
  --LOCKED: #aaaaaa;
  --UNLOCKED: #ffffff;
  --COMPLETED: #ffffff;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #282832;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 100;
}

.App-link {
  color: #61dafb;
}

.PrimaryButton {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  border-radius: 10px;
  border-bottom-width: 8px;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  background-color: var(--primary);
  border-bottom-color: var(--darkGreen);
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}
.PrimaryButton:hover {
  background-color: var(--primaryHover);
  transition: background-color 0.2s;
}

.PrimaryButton:disabled {
  transition: background-color 0.2s;
  background-color: lightgray;
  border-bottom-color: gray;
  color: var(--darkBackground);
  font-size: 1.5rem;
  font-weight: 600;
  cursor: not-allowed;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #1e1e1e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #5a5a5a;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

::-webkit-scrollbar-button {
  height: 16px;
  background-color: #1e1e1e;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M12 8l6 6H6z'/%3E%3C/svg%3E");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M12 16l-6-6h12z'/%3E%3C/svg%3E");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
}
