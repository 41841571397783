.privacy-policy-container {
  color: #bbbbbb;
}

/* Link style */
a {
  color: var(--primary);
  text-decoration: none;
}

a:hover {
  color: var(--secondary);
}
