.sub-main-container {
  margin-top: 5vh;
  width: 80vw;
  justify-self: center;
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: center;
  /* background-color: wheat; */
}
.reverse {
  flex-direction: row-reverse;
}

.section-image {
  max-height: 50vh;
  height: 100%;
  border-radius: 12px;
  border: 8px solid #0b0b0b;
}

.section-left-container {
  max-width: 50%;
  height: 100;
  align-self: center;
}

.section-right-container {
  /* width: 40%; */
  height: 100%;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.media-container {
  /* aspect-ratio: 16/9; */
  max-width: 25vw;
  margin-left: auto;
  margin-right: auto;
}

.section-header {
  color: var(--primary);
  font-size: 48px;
  font-weight: 700;
  margin: 10px;
}

.section-description {
  color: var(--text);
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 25px;
}

@media (max-width: 1260px) {
  .sub-main-container {
    flex-direction: column;
    align-items: center;
    width: 90vw;
    margin-top: 50px;
  }

  .section-left-container,
  .section-right-container {
    width: 100%;
  }

  .media-container {
    max-width: 60vw;
    margin-top: 20px;
  }

  .section-header {
    font-size: 36px;
  }

  .section-description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .sub-main-container {
    width: 95vw;
    margin-top: 30px;
    gap: 20px;
  }

  .media-container {
    max-width: 80vw;
  }

  .section-header {
    font-size: 28px;
  }

  .section-description {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .sub-main-container {
    flex-direction: column;
    width: 90vw;
    padding: 10px;
  }

  .section-left-container,
  .section-right-container {
    max-width: none;
    width: 90vw;
    text-align: center;
  }

  .media-container {
    width: 95%;
    margin-top: 20px;
  }

  .section-header {
    font-size: 28px;
  }

  .section-description {
    font-size: 16px;
  }
}
