.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: #13141a;
}

.logo-container {
  display: flex;
  flex-direction: row;
  width: 29vw; /* need to do 29 since we have padding on left of 1vw*/
  justify-items: start;
  align-content: flex-start;
  padding-left: 2vw;
  align-items: center;
  cursor: pointer;
}

.logo-text {
  font-weight: 600;
}

.logo-image {
  width: 60px;
  height: 60px;
  border-radius: 15px;
}

.span-links {
  width: 68vw;
  justify-content: end;
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding-right: 2vw;
}

.nav-text {
  font-weight: 600;
  font-size: 24px;
  transition: color 0.3s ease-in-out;
  margin-right: 1%;
}

.nav-text:hover {
  color: var(--primary);
  cursor: pointer;
}

.header-link-container {
  display: none;
}
.nav-toggle {
  display: none;
}

/* Below from Header */
@media (max-width: 768px) {
  .container {
    height: 100%;
  }

  .header-link-container {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: var(--lightBackground);
  }
  .header-link-container.open {
    display: flex;
    padding: 0 2rem;
    width: fit-content;
    margin-top: 20vh;
    position: absolute;
    right: 0;
  }

  .header-link-container p {
    margin: 5px;
  }

  .header-link-container p {
    font-weight: 400;
    font-size: 24px;
    transition: color 0.3s ease-in-out;
  }

  .header-link-container p:hover {
    color: var(--primary);
    cursor: pointer;
  }

  .nav-toggle {
    display: block;
    position: absolute;
    right: 0;
    margin-right: 5px;
  }

  .nav-toggle p {
    font-size: 42px;
    display: flex;
  }

  .span-links {
    display: none;
  }
}
