.section-container {
  margin-top: 15vh;
  width: 45vw;
  height: auto;
  border-radius: 15px;
  justify-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: var(--lightBackground);
  padding: 50px 50px;
  margin-bottom: 20vh;
}

.header-text {
  color: var(--text);
  font-size: 40px;
  margin: 0;
  margin-bottom: 20px;
}

.paragraph-text {
  margin-bottom: 40px;
  color: var(--textTwo);
  font-size: 20px;
  width: 90%;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .section-container {
    width: 80vw;
    padding: 60px 30px;
    margin-top: 80px;
    margin-bottom: 15vh;
  }

  .header-text {
    font-size: 32px;
  }

  .paragraph-text {
    font-size: 14px;
    width: 100%;
  }
  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .section-container {
    width: 85vw;
    padding: 40px 20px;
    margin-top: 60px;
    margin-bottom: 10vh;
  }

  .header-text {
    font-size: 28px;
  }

  .paragraph-text {
    font-size: 14px;
    width: 100%;
  }
}
